/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'



const Languages: FC = () => {

  const intl = useIntl();

  const languages = [
    {
      lang: 'en',
      name: intl.formatMessage({id: 'LANG.ENGLISH'}),
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
      lang: 'zh',
      name: intl.formatMessage({id: 'LANG.MANDARIN'}),
      flag: toAbsoluteUrl('/media/flags/china.svg'),
    },
    {
      lang: 'es',
      name: intl.formatMessage({id: 'LANG.SPANISH'}),
      flag: toAbsoluteUrl('/media/flags/spain.svg'),
    },
    {
      lang: 'ja',
      name: intl.formatMessage({id: 'LANG.JAPANESE'}),
      flag: toAbsoluteUrl('/media/flags/japan.svg'),
    },
    {
      lang: 'de',
      name: intl.formatMessage({id: 'LANG.GERMAN'}),
      flag: toAbsoluteUrl('/media/flags/germany.svg'),
    },
    {
      lang: 'fr',
      name: intl.formatMessage({id: 'LANG.FRENCH'}),
      flag: toAbsoluteUrl('/media/flags/france.svg'),
    },
    {
      lang: 'ar',
      name: intl.formatMessage({id: 'LANG.ARABIC'}),
      flag: toAbsoluteUrl('/media/flags/united-arab-emirates.svg'),
    },
  ];

  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (
    <div
      className='menu-item px-1'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <i className="fa fa-solid fa-language me-4"></i>
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'MENU.LANGUAGE'})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt='Mumara'
            />
          </span>
        </span>
      </a>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.map((l) => (
          <div
            className='menu-item px-3'
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={l.flag} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export {Languages}
