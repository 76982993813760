import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import { AddQSWrapper } from '../pages/quick-send/AddQsWrapper'
// import {UsersPage} from '../pages/lists/UsersPage'

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../pages/lists/UsersPage'))
  const CFPage = lazy(() => import('../pages/custom-fields/CFPage'))
  const SuppressedPage = lazy(() => import('../pages/suppressed/SuppressedPage'))
  const ContactsPage = lazy(() => import('../pages/contacts/ContactsPage'))
  const BroadcastPage = lazy(() => import('../pages/broadcasts/BroadcastPage'))
  const SenderIdPage = lazy(() => import('../pages/senderids/SenderIdPage'))
  const ProviderPage = lazy(() => import('../pages/providers/ProviderPage'))
  const LogsPage = lazy(() => import('../pages/logs/LogsPage'))
  const StatsPage = lazy(() => import('../pages/stats/StatsPage'))
  const BillingPage = lazy(() => import('../pages/billing/BillingPage'))
  const ProfilePage = lazy(() => import('../pages/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../pages/account/AccountPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='quick-send'
          element={
            <SuspensedView>
              <AddQSWrapper />
            </SuspensedView>
          }
        />
        <Route
          path='lists/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='lists/custom-fields/*'
          element={
            <SuspensedView>
              <CFPage />
            </SuspensedView>
          }
        />
        <Route
          path='lists/suppressed/*'
          element={
            <SuspensedView>
              <SuppressedPage />
            </SuspensedView>
          }
        />
        <Route
          path='contacts/*'
          element={
            <SuspensedView>
              <ContactsPage />
            </SuspensedView>
          }
        />
        <Route
          path='broadcasts/*'
          element={
            <SuspensedView>
              <BroadcastPage />
            </SuspensedView>
          }
        />
        <Route
          path='senderids/*'
          element={
            <SuspensedView>
              <SenderIdPage />
            </SuspensedView>
          }
        />
        <Route
          path='providers/*'
          element={
            <SuspensedView>
              <ProviderPage />
            </SuspensedView>
          }
        />
        <Route
          path='stats/*'
          element={
            <SuspensedView>
              <StatsPage />
            </SuspensedView>
          }
        />
        <Route
          path='billing/*'
          element={
            <SuspensedView>
              <BillingPage />
            </SuspensedView>
          }
        />
        <Route
          path='logs/*'
          element={
            <SuspensedView>
              <LogsPage />
            </SuspensedView>
          }
        />
        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
