/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  title: string
  description: string
  color: string
  dataVal:any
}

const StatisticsWidget3: React.FC<Props> = ({className, title, description, color, dataVal}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = (dataVal: any) => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const labelColor = getCSSVariableValue('--kt-gray-800')
    const baseColor = getCSSVariableValue('--kt-' + color)
    const lightColor = getCSSVariableValue('--kt-' + color + '-light')

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, labelColor, baseColor, lightColor, dataVal)
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart(dataVal)
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, dataVal])

  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='text-dark text-hover-primary fw-bold fs-3 mb-2'>{title}</span>
          <span className='text-muted fw-semibold fs-7' dangerouslySetInnerHTML={{__html: description}}></span>
        </h3>
        <div className="card-toolbar"></div>
      </div>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column p-0 credit-block'>
        {
          dataVal !== undefined && dataVal.length > 0 ?
          <div
            ref={chartRef}
            className='statistics-widget-3-chart card-rounded-bottom'
            style={{height: '150px'}}
          ></div>
          : <div className='ra-no-data'> 
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em" className="svg-icon svg-icon-4x text-grey"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" fill="#6c757d"></path></svg> No Data Available
            </div>
        }
          
      </div>
      {/* end::Body */}
    </div>
  )
}

export {StatisticsWidget3}


function getChartOptions(
  height: number,
  labelColor: string,
  baseColor: string,
  lightColor: string,
  dataVal:any
): ApexOptions {
  
  const max = Math.max(...dataVal);
  // const maxRound = Math.ceil(max);
  const options: ApexOptions = {
    series: [
      {
        name: 'Credit',
        data: dataVal,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      // categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        position: 'front',
        stroke: {
          color: '#E4E6EF',
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: max+0.001 ,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '13px',
      },
      y: {
        formatter: function (val) {
          return '$' + val
        },
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 4,
    },
  }
  return options
}
