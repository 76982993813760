/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import axios from 'axios';
import moment from 'moment';
import { useAuth } from '../../../../app/modules/auth';
import Swal from 'sweetalert2';
import './notifications.css'

const DrawerMessenger =(id: any)=> {

  const intl = useIntl();
  const API_URL = process.env.REACT_APP_BASE_URL
  const {currentUser, logout} = useAuth()
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const getRecords = useCallback(async () => { 
    await axios.post(`${API_URL}/getLatestClientNotifications`, { user_id: currentUser?.id, api_token: currentUser?.token }, { 
    }).then(res => { // then print response status
      setLoading(false);
      if (res.data.status === 'success') {
        setNotifications(res.data.data)
      } else {
        setNotifications([])
      }
    }).catch(function (error) {
      if(error?.response?.status === 429 || error?.response?.status === "429") {
        Swal.fire({
          icon: 'warning',
          title: 'Session expired, <br /> please login again to continue.<br /><br />',
          showConfirmButton: false,
          timer: 2000
        });
        logout();  
      }
    });

  }, [currentUser, logout, API_URL]);

    useEffect(() => {
        getRecords();
    }, [getRecords]);

    useEffect(() => {
      setInterval(getRecords, 240000);
    }, [getRecords]);

  return(
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
        <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-row me-3'>
              <h2 className='fs-4 fw-bolder text-gray-900 text-hover-primary me-5 mb-1 lh-1 mt-1'>
                {intl.formatMessage({id: 'NOTIFICATIONBAR.TITLE'})}
              </h2> 
              <span className="badge badge-success fw-bolder text-link" onClick={() => navigate("/logs/notifications")}>
                <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" className="svg-icon btn-icon text-white me-1" data-name="Material--RemoveRedEye"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 6.5A9.77 9.77 0 003.18 12c1.65 3.37 5.02 5.5 8.82 5.5s7.17-2.13 8.82-5.5A9.77 9.77 0 0012 6.5zm0 10c-2.48 0-4.5-2.02-4.5-4.5S9.52 7.5 12 7.5s4.5 2.02 4.5 4.5-2.02 4.5-4.5 4.5z" opacity="0.3" fill="#FFF"></path><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 13A9.77 9.77 0 013.18 12C4.83 8.63 8.21 6.5 12 6.5s7.17 2.13 8.82 5.5A9.77 9.77 0 0112 17.5zm0-10c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5 4.5-2.02 4.5-4.5-2.02-4.5-4.5-4.5zm0 7a2.5 2.5 0 010-5 2.5 2.5 0 010 5z" fill="#FFF"></path></svg>
                {intl.formatMessage({id: 'NOTIFICATIONBAR.VIEWALL'})}
              </span>
            </div>
          </div>

          <div className='card-toolbar'>
            <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </div>
          </div>
        </div>

        <div className="card-body position-relative sidebar-notifications" id="kt_drawer_notification">
          <div className='scroll-y me-n5 pe-5'>

            {
              loading === true ?
              <div className='empty-loader'>
                <div className="loading">x</div>
              </div>
              : null
            }

            {
              notifications !== undefined && notifications.length > 0 ?
              <>
                {notifications?.map(p=> <div key={p.id}>

                  {
                    p.type === 1 || p.type === "1" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/general/public.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    p.type === 2 || p.type === "2" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/general/localoffer.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    p.type === 3 || p.type === "3" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/general/campaign.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    p.type === 4 || p.type === "4" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/general/campaign.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    p.type === 5 || p.type === "5" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/general/accountbalance.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    p.type === 6 || p.type === "6" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/general/mobilefriendly.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    p.type === 7 || p.type === "7" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/general/public.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    p.type === 8 || p.type === "8" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/general/localoffer.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                  {
                    p.type === 9 || p.type === "9" ? 
                    <div className="alert alert-dark flex-nowrap d-flex br-1 py-5" role="alert">
                      <div className="alert-icon me-1">
                        <KTSVG path='/media/icons/duotune/files/fil010.svg' className='svg-icon-1 text-dark' />
                      </div>
                      <div className="alert-text">
                        <div dangerouslySetInnerHTML={ {__html: p.notification} } />
                        <div className='alert-time'>{moment(p.created_at).add(currentUser.time_zone, 'hours').fromNow()}</div>
                      </div>
                    </div>
                    : null
                  }

                </div>)}
              </>
              : <div className="ra-no-data">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24" width="1em" className="svg-icon svg-icon-4x text-grey"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" fill="#6c757d"></path></svg> 
                  {intl.formatMessage({id: 'NOTIFICATIONBAR.NODATA'})}
                </div>
            }
          </div>
        </div>

      </div>
    </div>
  )
}

export {DrawerMessenger}
