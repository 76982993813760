import axios from 'axios'
import {AuthModel} from './_models'

const API_URL = process.env.REACT_APP_BASE_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/sendEmailResetPasswordLink`
export const RESET_PASSWORD_URL = `${API_URL}/resetPassword`

// Server should return AuthModel
export function login(email: string, password: string) {
  // console.log(LOGIN_URL);
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function resetPass(password: string, token: string) {
  return axios
    .post(RESET_PASSWORD_URL, {password, token})
    .then((response) => response.data)
    
}