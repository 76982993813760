const QUERIES = {
  USERS_LIST: 'users-list',
  CUSTOM_FIELDS: 'custom-fields',
  SUPPRESSED: 'suppressed',
  CONTACTS: 'contacts',
  BROADCASTS: 'broadcasts',
  SHCEDULE: 'schedule',
  SENDERIDS: 'senderids',
  PROVIDERS: 'providers',
  STATISTICS: 'statistics',
  GLOBALSTATS: 'global-stats',
  DOCUMENTS: 'documents',
}

export {QUERIES}
