import React from 'react'
import './loader.css';

const Loader =()=> {
    return(
        <div className="preloading" id="preloading">
			<div className="preloader">x</div>
		</div>
    )
}

export default Loader;