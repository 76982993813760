/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import axios from 'axios'
import {toAbsoluteUrl} from '../../../helpers'
import Swal from 'sweetalert2'

const serverUrl = process.env.REACT_APP_SERVER_URL

const HeaderUserMenu: FC = () => {

  const intl = useIntl()
  const API_URL = process.env.REACT_APP_BASE_URL
  const {currentUser, logout} = useAuth()
  const [balance, setBalance] = useState(0);
  let loggedInUser = JSON.parse(localStorage.getItem('user'));
  let photo2 = loggedInUser.photo;
  var photo = `${serverUrl}/storage/users/${currentUser?.id}/profile/${photo2}`;
  // setInterval(() => {
  //   photo = `${serverUrl}/storage/users/${currentUser?.id}/profile/${photo2}`;
  // }, 5000);

  function calculate(val) {
		const num = parseFloat(val); // Read Value from DOM
		const rounded = num.toFixed(2); // Round Number
		return rounded;
	}

  let userCredit = JSON.parse(localStorage.getItem('cr'));

  const clientBalance = useCallback(async () => {
		await axios.post(`${API_URL}/getClientBalance`, { user_id: currentUser?.id, api_token: currentUser?.token, page:1, items_per_page:10 }, { 
		}).then(res => { 
			// setLoading(false);
			if (res?.data?.status === 'success') {
			setBalance(res?.data?.data?.credit);
			localStorage.setItem("cr", calculate(res?.data?.data?.credit));
      setBalance(userCredit);
			// setLoading(false);
			} else {
			setBalance(0);
			// setLoading(false);
			}
		})
	}, [API_URL, currentUser, userCredit]);

	useEffect(() => {
		clientBalance();
	}, [clientBalance]);

  const signOut =()=> {
    const listData= {user_id: currentUser?.id, api_token: currentUser?.token}
    axios.post(`${API_URL}/clientLogoutActivity`, listData, { // receive two parameter endpoint url ,form data 
    })
    .then(dataRes => {
        if (dataRes.data.status === 'success') {
            Swal.fire({
              icon: 'success',
              title: 'You have been successfully logged out<br /><br />',
              showConfirmButton: false,
              timer: 2000
            });
            logout();

        } else {
            Swal.fire({
              icon: 'success',
              title: 'You have been successfully logged out<br /><br />',
              showConfirmButton: false,
              timer: 2000
            });
            logout();
        }
    })
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {
              currentUser?.photo !== null ?
              <img alt='Logo' src={photo} />
              : <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
            }
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.last_name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <div className='fw-bold text-muted text-hover-dark fs-7' style={{maxWidth: 165, overflow: `hidden`, whiteSpace: `normal`, wordBreak: `break-all`}}>
              {currentUser?.email}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-1'>
        <Link to={'/profile/edit'} className='menu-link px-5'>
          <i className="fa fa-user-circle"></i> {intl.formatMessage({id: 'MENU.EDITPROFILE'})}
        </Link>
      </div>

      <div className='menu-item px-1 my-1'>
        <Link to='/account/settings' className='menu-link px-5'>
          <i className="fa fa-solid fa-gear"></i> {intl.formatMessage({id: 'MENU.ACCOUNTSETTINGS'})}
        </Link>
      </div>

      <div className='menu-item px-1 my-1'>
        <Link to='/billing/invoices' className='menu-link px-5'>
          <i className="fa fa-solid fa-credit-card"></i> {intl.formatMessage({id: 'MENU.MANAGEFUNDS'})} 
          <small className="ms-2 text-gray-600"> (<span className='fs-7'>$</span>{calculate(balance)})</small>
        </Link>
      </div>

      <Languages />

      <div className='separator my-2'></div>   

      <div className='menu-item px-1'>
        <a onClick={signOut} className='menu-link px-5'>
          <i className="fa fa-power-off"></i> {intl.formatMessage({id: 'MENU.SIGNOUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
