/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
  password: '',
}

localStorage.removeItem("notify");
localStorage.removeItem("reactQueryDevtoolsSortFn");
localStorage.removeItem("listcntcte");
localStorage.removeItem("spressede");
localStorage.removeItem("cstmflde");
localStorage.removeItem("spressede");
localStorage.removeItem("trstatse");
localStorage.removeItem("glbstatse");
localStorage.removeItem("brdlogse");
localStorage.removeItem("brdstatse");
localStorage.removeItem("sndre");
localStorage.removeItem("schdle");
localStorage.removeItem("cr");
localStorage.removeItem("prvde");
localStorage.removeItem("pdocse");
localStorage.removeItem("nlogse");
localStorage.removeItem("llogse");
localStorage.removeItem("logse");
localStorage.removeItem("liste");
localStorage.removeItem("cntcse");
localStorage.removeItem("brdcste");
localStorage.removeItem("invce");
localStorage.removeItem("stats");
localStorage.removeItem("kt_theme_mode_value");

export function Login() {

  const intl = useIntl();

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'LOGIN.VALID.WRONGEMAILFORMAT'}))
      .max(50, intl.formatMessage({id: 'LOGIN.VALID.MAX50'}))
      .required(intl.formatMessage({id: 'LOGIN.VALID.EMAILREQUIRED'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'LOGIN.VALID.MIN3'}))
      .max(50, intl.formatMessage({id: 'LOGIN.VALID.MAX50'}))
      .required(intl.formatMessage({id: 'LOGIN.VALID.PASSWORDREQUIRED'})),
  })

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const themeColor = localStorage.getItem("kt_theme_mode_value");

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        // console.log(values, auth)
        saveAuth(auth)
        //const {data: user} = await getUserByToken(auth.token)
        setCurrentUser(auth)
        if(!auth) {
          console.log("error")
        }
      } catch (error) {
        if(error?.response) {
          setStatus('The login details are incorrect.')
        } else {
          setStatus("Server not responding please try again later")
        }
        // console.log(error)
        // console.log(JSON.stringify(error.response))
        // console.error(error)
        saveAuth(undefined)
        // setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {
          themeColor !== "dark" || localStorage.getItem("kt_theme_mode_value") !== "dark" ?
          <Link to='/'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-50px' />
          </Link>
          : <Link to='/'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-light.png')} className='h-50px' />
            </Link>
        }
        <h1 className='text-dark fw-bolder mb-3 mt-5'>{intl.formatMessage({id: 'LOGIN.TITLE'})}</h1>
        <div className='text-gray-500 fw-semibold fs-6'>{intl.formatMessage({id: 'LOGIN.DESC'})}</div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        null
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'LOGIN.EMAIL'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'LOGIN.EMAIL'})}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({id: 'LOGIN.PASSWORD'})}</label>
        <input
          placeholder={intl.formatMessage({id: 'LOGIN.PASSWORD'})}
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-success'>
          {intl.formatMessage({id: 'LOGIN.FORGOTPASSWORD'})}
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-success'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'LOGIN.CONTINUE'})}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'LOGIN.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
