/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { resetPass } from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useIntl} from 'react-intl'

const initialValues = {
  password: '',
  changepassword: '',
}
export function ResetPassword() {

    const intl = useIntl();
    const navigate = useNavigate();
    function getLastUrlSegment(url: string) {
        return new URL(url).pathname.split('/').filter(Boolean).pop();
    }
    const token = getLastUrlSegment(window.location.href);
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const themeColor = localStorage.getItem("kt_theme_mode_value");

    const restPasswordSchema = Yup.object().shape({
      password: Yup.string()
        .min(6, intl.formatMessage({id: 'RESETPASSWORD.MIM6SYMBOLS'}))
        .max(50, intl.formatMessage({id: 'RESETPASSWORD.MAX50SYMBOLS'}))
        .required(intl.formatMessage({id: 'RESETPASSWORD.PASSWORDREQUIRED'})),
      changepassword: Yup.string()
        .required(intl.formatMessage({id: 'RESETPASSWORD.PASSWORDCONFIRMATIONREQUIRED'}))
        .when('password', {
          is: (val: string) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref('password')], intl.formatMessage({id: 'RESETPASSWORD.CONFIRMPASSWORDNOTMATCH'})),
        }),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: restPasswordSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
        setStatus("")
        setSuccess(false)
        setLoading(true)
        try {
            const data = await resetPass(values.password,token)
            if(data.status === "success") {
                setSubmitting(false)
                setLoading(false)
                setStatus("");
                setSuccess(true);
                setTimeout(() => {
                    navigate('/auth/login');
                }, 2000);
            } else {
                setStatus(intl.formatMessage({id: 'RESETPASSWORD.TOKENNOTEXIST'}))
                setSubmitting(false)
                setLoading(false)
                setSuccess(false)
            }
        } catch (error) {
            console.log(error)
            console.error(error)
            setStatus(intl.formatMessage({id: 'RESETPASSWORD.TOKENNOTEXIST'}))
            setSubmitting(false)
            setLoading(false)
            setSuccess(false)
        }
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    const goToLogin =()=> {
        navigate('/auth/login');
    }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {
          themeColor !== "dark" || localStorage.getItem("kt_theme_mode_value") !== "dark" ?
          <Link to='/'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-50px' />
          </Link>
          : <Link to='/'>
              <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-light.png')} className='h-50px' />
            </Link>
        }
        <h1 className='text-dark fw-bolder mb-3 mt-5'>{intl.formatMessage({id: 'RESETPASSWORD.RESETYOURPASSWORD'})}</h1>
        <div className='text-gray-500 fw-semibold fs-6'>{intl.formatMessage({id: 'RESETPASSWORD.RESETYOURPASSWORDSESC'})}</div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-8 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {success && (
        <div className='mb-8 alert alert-success'>
          <div className='alert-text font-weight-bold'>{intl.formatMessage({id: 'RESETPASSWORD.PASSWORDCHANGED'})}</div>
        </div>
      )}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'RESETPASSWORD.NEWPASSWORD'})}</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'RESETPASSWORD.NEWPASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          {intl.formatMessage({id: 'RESETPASSWORD.NEWPASSWORDDESC'})}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({id: 'RESETPASSWORD.CONFIRMPASSWORD'})}</label>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'RESETPASSWORD.CONFIRMPASSWORD'})}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center d-flex flex-row justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-success w-50 mb-5 me-1'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'RESETPASSWORD.BUTTONRESETPASSWORD'})}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'RESETPASSWORD.PLEASEWAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
        type='button'
        id='kt_login_signup_form_cancel_button'
        className='btn btn-lg btn-light w-50 mb-5'
        onClick={goToLogin}
        >
          {intl.formatMessage({id: 'RESETPASSWORD.BUTTONCANCEL'})}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
